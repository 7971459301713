'use strict'

import SelectManager from 'assets/core/js/module/selectManager'

const pageTypesToIgnore = ['iframe-search-form']

export default function (): void {
  const initSelects = function (): void {
    if (!pageTypesToIgnore.includes(document.body.getAttribute('data-pagetype') as string)) {
      document.querySelectorAll<HTMLSelectElement>('select:not(.choices__input)').forEach(function (el) {
        if (!el.closest('.o-select-custom')) {
          SelectManager.initSelect(el)
        }
      })
    }
  }

  document.body.addEventListener('selects.init', initSelects)

  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          SelectManager.initSelect(entry.target as HTMLSelectElement)
        }, 0)

        observer.unobserve(entry.target)
      }
    })
  })

  document.querySelectorAll<HTMLSelectElement>('select:not(.choices__input)').forEach(function (el) {
    if (!el.closest('.o-select-custom')) {
      observer.observe(el)
    }
  })
}

'use strict'

import { Gesture } from '@use-gesture/vanilla'

export interface ModalConfig {
  rootEl?: HTMLElement
  class?: string
  onOpen?: () => void
  onClose?: () => void
}

export default class Modal {
  config!: ModalConfig
  element!: HTMLElement
  isOpened!: boolean
  fadeEl!: HTMLElement
  handleEl!: HTMLElement | null
  wrapperEl!: HTMLElement
  closeEl!: HTMLElement | null
  contentEl!: HTMLElement
  gesture!: Gesture

  constructor(element: string | HTMLElement, config: ModalConfig = {}) {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element) as HTMLElement
    }

    if (!el || typeof el === 'string') {
      return
    }

    if (!config.rootEl) {
      config.rootEl = document.body
    }

    this.element = el
    config.rootEl.appendChild(this.element)
    this.config = config
    this.isOpened = false

    this.setElements()

    if (this.config.class) {
      this.element.classList.add(this.config.class)
    }

    this.initEvents()

    this.element.setAttribute('data-init', 'true')
  }

  setElements(): void {
    const wrapperEl = this.element.querySelector('.dca-modal__wrapper') as HTMLElement
    const fadeEl = this.element.querySelector('.dca-modal__fade') as HTMLElement
    const contentEl = this.element.querySelector('.dca-modal__content') as HTMLElement

    this.wrapperEl = wrapperEl
    this.fadeEl = fadeEl
    this.contentEl = contentEl
    this.handleEl = this.element.querySelector('.dca-modal__handle')
    this.closeEl = this.element.querySelector('.dca-modal__close')
  }

  getElement(): HTMLElement {
    return this.element
  }

  initEvents(): void {
    if (this.closeEl) {
      this.fadeEl.addEventListener('click', () => {
        this.close()
      })

      this.closeEl.addEventListener('click', () => {
        this.close()
      })

      document.body.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          this.close()
        }
      })
    }

    if (this.handleEl) {
      this.gesture = new Gesture(
        this.handleEl,
        {
          onDragEnd: (state) => {
            if (state.direction[1] === 1) {
              this.toggle()
            }
          },
        },
        {
          drag: {
            axis: 'y',
          },
        }
      )
    }
  }

  open(): Modal {
    document.body.classList.add('dca-modal--focused')

    this.element.removeAttribute('hidden')

    setTimeout(() => {
      this.element.classList.add('dca-modal--opened')
      this.element.focus()
      this.isOpened = true
    }, 0)

    this.element.dispatchEvent(new CustomEvent('modal.opened'))

    if (this.config.onOpen) {
      this.config.onOpen()
    }

    return this
  }

  close(): Modal {
    this.element.classList.remove('dca-modal--opened')

    setTimeout(() => {
      document.body.classList.remove('dca-modal--focused')
      this.element.setAttribute('hidden', 'hidden')
      this.isOpened = false

      this.element.dispatchEvent(new CustomEvent('modal.closed'))

      if (this.config.onClose) {
        this.config.onClose()
      }
    }, 250)

    return this
  }

  setContent(content: string | HTMLElement): Modal {
    if (typeof content === 'string') {
      this.contentEl.innerHTML = content
    } else {
      this.contentEl.appendChild(content)
    }

    return this
  }

  toggle(): Modal {
    if (this.isOpened) {
      this.close()
    } else {
      this.open()
    }

    return this
  }

  destroy(): void {
    document.body.removeChild(this.element)
  }
}

'use strict'

interface Listener {
  key: string | null
  callable: CallableFunction
}

interface ListenableStorage {
  storage: Storage
  listeners: Listener[]
}

interface BrowserStorage {
  local: null | ListenableStorage
  session: null | ListenableStorage
}

type StorageType = 'local' | 'session'

const storage: BrowserStorage = {
  local: null,
  session: null,
}

try {
  window.localStorage.setItem('detect_property', '1')
  window.localStorage.removeItem('detect_property')
  storage.local = {
    storage: window.localStorage,
    listeners: [],
  }
} catch (e) {}

try {
  window.sessionStorage.setItem('detect_property', '1')
  window.sessionStorage.removeItem('detect_property')
  storage.session = {
    storage: window.sessionStorage,
    listeners: [],
  }
} catch (e) {}

let localStorageEventListener: ((event: StorageEvent) => void) | null = null

function notify(storage: ListenableStorage, key: string | null = null): void {
  for (const listener of storage.listeners) {
    if (key === null || key === listener.key) {
      listener.callable()
    }
  }
}

export default {
  getItem(type: StorageType, key: string) {
    const store = storage[type]

    if (!store || !store.storage.getItem) {
      return false
    }

    let item = store.storage.getItem(key)

    if (!item) {
      return false
    }

    try {
      item = JSON.parse(item)
    } catch (error) {}

    return item
  },

  setItem(type: StorageType, key: string, value: unknown) {
    const store = storage[type]

    if (!store || !store.storage.setItem) {
      return false
    }

    store.storage.setItem(key, JSON.stringify(value))
    notify(store, key)

    return true
  },

  removeItem(type: StorageType, key: string) {
    const store = storage[type]

    if (!store || !store.storage.removeItem) {
      return false
    }

    store.storage.removeItem(key)
    notify(store, key)

    return true
  },

  clear(type: StorageType) {
    const store = storage[type]

    if (!store || !store.storage.clear) {
      return false
    }

    store.storage.clear()
    notify(store)

    return true
  },

  /**
   * Listen to modifications on the given storage. The given listener will be
   * triggered each time the given key is modified. Events are also triggered
   * on full storage clears. Events are triggered for a key update on the
   * current page as long as the `setItem` method is used, and even if the
   * value did not change. Cross-pages events are supported for the local
   * storage only, using `storage` events on the `window`.
   */
  listen(type: StorageType, key: string, listener: CallableFunction): boolean {
    const store = storage[type]
    if (store === null) {
      return false
    }

    store.listeners.push({
      key,
      callable: listener,
    })

    // It ensures a single listener on storage events.
    if (type === 'local' && localStorageEventListener === null) {
      localStorageEventListener = (event: StorageEvent) => {
        notify(store, event.key)
      }

      window.addEventListener('storage', localStorageEventListener)
    }

    return true
  },
}

'use strict'

import 'choices.js/public/assets/styles/choices.css'
import Choices, { Options, ClassNames, Choice, Group } from 'choices.js'
import { WrappedInput, WrappedSelect } from 'choices.js/public/types/src/scripts/components'

type TemplateOptions = Record<'classNames' | 'allowHTML', { list: string; listDropdown: string }>

interface SelectOptions {
  applyButtonText?: null | string
}

export type Select = Choices

const defaultConfig: SelectOptions | Options = {
  callbackOnCreateTemplates: function (template) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config: SelectOptions & Options = this.config

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const passedElement: WrappedInput | WrappedSelect = this.passedElement

    return {
      choice: ({ classNames }: { classNames: ClassNames }, data: Record<string, unknown>): Element => {
        return template(`
        <div
    class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
    data-select-text="${config?.itemSelectText}"
    data-choice
    ${data.value === '' && passedElement?.element?.hasAttribute('required') ? 'data-disabled="true"' : ''}
    data-id="${data.id as string}"
    data-value="${data.value as string}"
    data-selected="${data.selected ? '1' : '0'}"
    ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
    id="${data.elementId as string}"
    ${(data.groupId as number) > 0 ? 'role="treeitem"' : 'role="option"'}
    >
    ${data.label as string}
    </div>
      `)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dropdown({ classNames: { list, listDropdown } }: TemplateOptions): HTMLDivElement {
        const template = document.createElement('div')

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        template.classList.add(list, listDropdown)
        template.setAttribute('aria-expanded', 'false')

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const config: SelectOptions & Options = this.config

        if (typeof config.applyButtonText !== 'undefined' && config.applyButtonText !== null) {
          const div = document.createElement('div')
          div.innerHTML = `<div class="choices__list-apply"><span class="dca-button dca-button--secondary-outline">${config.applyButtonText}</span></div>`
          template.setAttribute('data-with-apply', 'true')
          template.appendChild(div)
        }

        return template
      },
    }
  },
  searchEnabled: false,
  searchChoices: false,
  shouldSort: false,
  applyButtonText: null,
  resetScrollPosition: false,
}

const defaultMultipleConfig = {
  renderSelectedChoices: 'always',
}

const selects: Record<string, Select> = {}

export default {
  initSelect(el: HTMLSelectElement, config?: Partial<Options>) {
    if (!el) {
      return
    }

    if (!el.getAttribute('data-choice')) {
      let id = el.getAttribute('id')

      if (!id) {
        do {
          id = Math.random().toString(36).substr(2, 9)
        } while (typeof selects[id] !== 'undefined')

        el.setAttribute('id', id)
      }

      let choicesConfig = Object.assign({}, defaultConfig, config)

      if (el.multiple === true) {
        choicesConfig = Object.assign(choicesConfig, defaultMultipleConfig)
      }

      if (el.hasAttribute('data-apply-button')) {
        choicesConfig = Object.assign(choicesConfig, { applyButtonText: el.getAttribute('data-apply-button') })
      }

      selects[id] = new Choices(el, choicesConfig)
      const currentSelect = selects[id]
      if (el.hasAttribute('data-apply-button')) {
        currentSelect?.dropdown?.element?.querySelector('.choices__list-apply')?.addEventListener('click', function () {
          currentSelect.hideDropdown()
        })
      }

      if (el.multiple === true && selects[id] && selects[id]?.input) {
        // @ts-ignore
        selects[id].input.placeholder = el.getAttribute('data-placeholder') as string
        // @ts-ignore
        selects[id].input.element.readOnly = true
      }

      // fix ie11 issue https://github.com/jshjohnson/Choices/issues/834
      if (!selects[id]?.dropdown.element.classList.contains('choices__list--dropdown')) {
        selects[id]?.dropdown.element.classList.add('choices__list--dropdown')
      }

      // fix chrome bug where the scrollbar space will still appear even if the scrollbar itself is not displayed
      currentSelect?.passedElement.element.addEventListener('showDropdown', function (this: Element) {
        // @ts-ignore
        this.closest('.choices')
          .querySelectorAll('.choices__list .choices__item')
          .forEach(function (el: Element) {
            el.classList.add('choices__item--reflow')

            window.setTimeout(() => {
              el.classList.remove('choices__item--reflow')
            }, 0)
          })
      })

      // fix ie11 bug when selecting an option would retrigger the html validation
      currentSelect?.passedElement.element.addEventListener('choice', function (this: HTMLSelectElement, e) {
        if (this.hasAttribute('required')) {
          this.removeAttribute('required')
          this.setAttribute('required', 'required')
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          this.value = e.detail?.choice?.value
        }
      })

      if (el.multiple === true) {
        currentSelect?.passedElement.element.addEventListener('choice', function (this: Element, e) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const detail: { choice: Choice } = e.detail

          if (detail && detail.choice.disabled === false && detail.choice.selected === true) {
            setTimeout(() => {
              currentSelect.removeActiveItemsByValue(detail.choice.value as string)
              el.dispatchEvent(
                new CustomEvent('change', {
                  bubbles: true,
                })
              )
            })
          }
        })
        if ([...el.options].filter((x) => x.selected).length > 0) {
          el.parentNode?.querySelector('input.choices__input')?.classList.add('choices__input--no-placeholder')
        }
        el.addEventListener('change', function () {
          if (el.children.length === 0) {
            el.parentNode?.querySelector('input.choices__input')?.classList.remove('choices__input--no-placeholder')
          } else {
            el.parentNode?.querySelector('input.choices__input')?.classList.add('choices__input--no-placeholder')
          }
        })
      }

      return currentSelect
    }

    return false
  },

  getSelect(id: string) {
    if (!selects[id]) {
      this.initSelect(document.getElementById(id) as HTMLSelectElement)
    }

    return selects[id] as Select
  },

  getValue(id: string): string | Array<string> {
    if (!id) {
      return ''
    }

    if (!selects[id]) {
      this.initSelect(document.getElementById(id) as HTMLSelectElement)
    }

    return selects[id]?.getValue(true) as string | Array<string>
  },

  setValue(id: string, value: string) {
    if (!id || typeof value === 'undefined') {
      return false
    }

    if (!selects[id]) {
      this.initSelect(document.getElementById(id) as HTMLSelectElement)
    }

    selects[id]?.setChoiceByValue(value)
    selects[id]?.passedElement.element.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          src: 'setValue',
        },
      })
    )

    return true
  },

  removeValue(id: string, value: string): boolean {
    if (!id || typeof value === 'undefined' || !selects[id]) {
      return false
    }

    selects[id]?.removeActiveItemsByValue(value)
    selects[id]?.passedElement.element.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          src: 'setValue',
        },
      })
    )

    return true
  },

  removeValues(id: string): boolean {
    if (!id || !selects[id]) {
      return false
    }

    // @ts-ignore
    selects[id]?.removeActiveItems()
    selects[id]?.passedElement.element.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          src: 'setValue',
        },
      })
    )

    return true
  },

  /*
   * values:
    {
      choices: {
        value1: "label1",
        value2: "label2"
      },
      value: value1 // the selected value
    }
  */
  async setValues(id: string, values: { choices: Record<string, string>; value: string }): Promise<void> {
    const formattedValues = []
    for (const value in values.choices) {
      formattedValues.push({
        value: value.toString(),
        label: values.choices[value],
        selected: values.value ? values.value.toString() === value.toString() : false,
      })
    }

    await selects[id]?.setChoices(formattedValues, 'value', 'label', true)

    if (values.value) {
      selects[id]?.setChoiceByValue(values.value)
      selects[id]?.passedElement.element.dispatchEvent(
        new CustomEvent('change', {
          detail: {
            src: 'setValue',
          },
        })
      )
    }
  },

  clear(id: string): boolean {
    if (!id || !selects[id]) {
      return false
    }

    selects[id]?.clearChoices()

    return true
  },

  async clearSelection(id: string): Promise<void> {
    if (selects[id]?.passedElement.element.multiple === false) {
      selects[id]?.setChoiceByValue('0')
    } else {
      const initialChoices: Group[] = []
      selects[id]?.passedElement?.element?.parentNode?.parentNode?.querySelectorAll('.choices__list--dropdown .choices__item').forEach(function (el) {
        initialChoices.push({
          value: el.getAttribute('data-value') as string,
          // @ts-ignore
          label: el.innerHTML,
          disabled: el.getAttribute('aria-disabled') !== null,
        })
      })

      selects[id]?.destroy()
      selects[id]?.init()
      await selects[id]?.setChoices(initialChoices, 'value', 'label', true)
    }
  },
}

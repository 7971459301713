'use strict'

import collapsibleBlock from 'assets/core/js/module/collapsibleBlock'
import readMoreText from 'assets/core/js/module/readMoreText'
import common from 'assets/themes/campings/js/page/common/index'
import help from 'assets/themes/campings/js/page/common/help'
import languageSelector from 'assets/core/js/module/languageSelector'
import stickyObserver from 'assets/core/js/module/stickyObserver'
import trustPilot from 'assets/themes/campings/js/page/common/trustPilot'
import tooltip from 'assets/themes/campings/js/module/tooltip'

import HeaderMenu from '@ui/Header/Menu/BS/component'

common.preInit()

document.addEventListener('DOMContentLoaded', function () {
  common.init()

  HeaderMenu()

  collapsibleBlock()
  readMoreText()

  help()
  languageSelector()
  stickyObserver()
  trustPilot()

  tooltip()
})

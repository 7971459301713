'use strict'

import { Gesture } from '@use-gesture/vanilla'
import * as coreCommon from 'assets/core/js/common'

export const Events = {
  MOBILE_BOTTOM_PANEL_VISIBLE: 'mobile.bottom.panel.visible',
  MOBILE_BOTTOM_PANEL_HIDDEN: 'mobile.bottom.panel.hidden',
  MOBILE_BOTTOM_PANEL_CLOSE: 'mobile.bottom.panel.close',
  MOBILE_BOTTOM_PANEL_OPENED: 'mobile.bottom.panel.opened',
  MOBILE_BOTTOM_PANEL_CLOSED: 'mobile.bottom.panel.closed',
}

const mobileBottomPanels: {
  [key: string]: {
    element: Element
    gesture: Gesture
  }
} = {}

const open = (element: HTMLElement): void => {
  document.body.classList.add('dca-mobile-bottompanel--focused')
  document.body.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_VISIBLE))
  element.classList.add('dca-mobile-bottompanel--opened')

  element.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_OPENED))

  const closeButtonEl = element.querySelector<HTMLButtonElement>('.dca-mobile-bottompanel__close')

  if (!closeButtonEl) {
    return
  }

  closeButtonEl.tabIndex = 0
}

const close = (element: HTMLElement): void => {
  document.body.classList.remove('dca-mobile-bottompanel--focused')
  document.body.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_HIDDEN))
  element.classList.remove('dca-mobile-bottompanel--opened')

  element.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_CLOSED))

  const closeButtonEl = element.querySelector<HTMLButtonElement>('.dca-mobile-bottompanel__close')

  if (!closeButtonEl) {
    return
  }

  closeButtonEl.tabIndex = -1
}

const toggle = (element: HTMLElement): void => {
  const isOpened = element.classList.contains('dca-mobile-bottompanel--opened')

  element.toggleAttribute('aria-hidden')

  if (isOpened) {
    close(element)
  } else {
    open(element)
  }
}

const mobileBottomPanel = function (element: HTMLElement, id: string): void {
  const fadeEl = element.querySelector('.dca-mobile-bottompanel__fade') as HTMLElement
  const handleEl = element.querySelector('.dca-mobile-bottompanel__handle') as HTMLElement
  const closeButtonEl = element.querySelector('.dca-mobile-bottompanel__close') as HTMLButtonElement

  const gesture = new Gesture(
    handleEl,
    {
      onDragEnd: (state) => {
        if (state.direction[1] === 1) {
          toggle(element)
        }
      },
    },
    {
      drag: {
        axis: 'y',
      },
    }
  )

  fadeEl.addEventListener('click', () => {
    toggle(element)
  })

  closeButtonEl.addEventListener('click', () => {
    toggle(element)
  })

  element.addEventListener(Events.MOBILE_BOTTOM_PANEL_CLOSE, () => {
    close(element)
  })

  element.setAttribute('data-init', 'true')

  mobileBottomPanels[id] = {
    element,
    gesture,
  }
}

/* istanbul ignore next */
const trapFocus = function (element: HTMLElement): void {
  element.addEventListener('keydown', function (e) {
    if (!element.classList.contains('dca-mobile-bottompanel--opened')) {
      return
    }

    const focusableEls = element.querySelectorAll<HTMLElement>(
      'a[href]:not([disabled]), button:not([disabled]):not([tabIndex="-1"]), input:not([disabled]):not([type="hidden"]):not([tabIndex="-1"]), select:not([disabled]):not([tabIndex="-1"]), textarea:not([disabled]):not([tabIndex="-1"])'
    )
    const firstFocusableEl = focusableEls[0]
    const lastFocusableEl = focusableEls[focusableEls.length - 1]
    const KEYCODE_TAB = 9

    if (!lastFocusableEl || !firstFocusableEl) {
      return
    }

    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
      /* tab */
    } else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  })
}

export default function (selector = '.dca-mobile-bottompanel__toggle'): void {
  if (!selector) {
    return
  }

  document.querySelectorAll(selector).forEach((el) => {
    el.addEventListener('click', (e) => {
      const targetEl = e.target as Element

      if (coreCommon.isDesktop()) {
        return
      }

      if (targetEl.classList.contains('dca-mobile-bottompanel__toggle')) {
        const targetAttr = el.getAttribute('data-panel-target')

        if (!targetAttr) {
          return
        }

        const popinEl = document.getElementById(targetAttr)

        if (!popinEl) {
          return
        }

        if (popinEl && popinEl.hasAttribute('data-init')) {
          toggle(popinEl)
          return
        }

        if (!mobileBottomPanels[targetAttr]) {
          mobileBottomPanel(popinEl, targetAttr)
        }

        trapFocus(popinEl)
        toggle(popinEl)
      }
    })
  })
}

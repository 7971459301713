'use strict'

import * as coreCommon from 'assets/core/js/common'

export default function (): void {
  if (coreCommon.isMobile()) {
    document.querySelectorAll('.dca-collapsible-block--collapsed-mobile').forEach((el) => el.classList.add('dca-collapsible-block--collapsed'))
  }

  document.addEventListener('click', function (e) {
    const target = e.target as HTMLElement
    if (target.matches('.dca-collapsible-block__toggle')) {
      const toggleTarget = target.getAttribute('data-target')

      if (!toggleTarget) {
        return
      }

      const toggleEl = document.querySelector(`.dca-collapsible-block[data-toggle="${toggleTarget}"]`)

      if (!toggleEl) {
        return
      }

      toggleEl.classList.toggle('dca-collapsible-block--collapsed')
    }
  })
}

'use strict'

export default function (): void {
  let tooltipActive = false
  let tooltipParentEl: HTMLElement | null = null

  document.addEventListener(
    'touchend',
    (e) => {
      const target = e.target as HTMLElement

      if (!tooltipActive && target.matches('.dca-tooltip .dca-tooltip__text')) {
        tooltipParentEl = target.closest('.dca-tooltip')
        tooltipParentEl?.classList.add('dca-tooltip--active')
        tooltipActive = true
      } else {
        if (tooltipActive && !tooltipParentEl?.contains(target)) {
          if (e.cancelable) {
            e.preventDefault()
          }
          tooltipParentEl?.classList.remove('dca-tooltip--active')
          tooltipParentEl = null
          tooltipActive = false
        }
      }
    },
    {
      capture: true,
      passive: false,
    }
  )
}

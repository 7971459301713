'use strict'

export default class ExpandableMenu {
  element!: Element
  isOpened!: boolean
  toggleEls!: NodeListOf<Element>
  contentEl!: Element | null

  constructor(element: Element | string) {
    let el: Element | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element)
    }

    if (!el || typeof el === 'string') {
      throw new Error('Element not found.')
    }

    this.element = el
    this.isOpened = false

    this.toggle = this.toggle.bind(this)

    this.toggleEls = this.element.querySelectorAll('.dca-menu__toggle')
    this.contentEl = this.element.querySelector('.dca-menu__content')

    if (this.toggleEls.length === 0) {
      throw new Error('Toggle element not found.')
    }

    if (!this.contentEl) {
      throw new Error('Content element not found.')
    }

    this.initEvents()
  }

  initEvents(): void {
    Array.from(this.toggleEls).forEach((el) => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      el.addEventListener('click', this.toggle)
    })

    document.addEventListener('click', (event) => {
      const target = event.target as HTMLElement
      if (!this.element.contains(target) && this.element.classList.contains('dca-menu--opened')) {
        this.toggle()
      }
    })
  }

  destroy(): void {
    Array.from(this.toggleEls).forEach((el) => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      el.removeEventListener('click', this.toggle)
    })
  }

  toggle(): ExpandableMenu {
    this.element.classList.toggle('dca-menu--opened')
    this.isOpened = !this.isOpened

    if (this.isOpened) {
      this.element.dispatchEvent(new CustomEvent('showMenu'))
    } else {
      this.element.dispatchEvent(new CustomEvent('hideMenu'))
    }

    return this
  }
}

'use strict'

import Modal from 'assets/core/js/module/modal'

export default function (rootEl?: Element): void {
  let modal: Modal | null = null

  rootEl = rootEl || document.body

  rootEl.querySelectorAll('.dca-toggle-text').forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault()

      const parentEl = el.parentNode

      if (!parentEl) {
        return
      }

      if (el.hasAttribute('data-modal')) {
        if (!modal) {
          const modalEl = el.parentNode.querySelector<HTMLElement>('.modal-full-text')

          if (!modalEl) {
            return
          }

          modal = new Modal(modalEl)
        }

        const textEl = el.previousElementSibling?.cloneNode(true) as Element

        if (!textEl) {
          return
        }

        const ellipsisEl: Element = textEl.querySelector('.dca-more-text-ellipsis') as Element

        textEl.removeChild(ellipsisEl)
        textEl.querySelector('.dca-more-text')?.removeAttribute('hidden')

        modal.setContent(textEl.innerHTML)
        modal.open()
      }

      if (!el.hasAttribute('data-modal')) {
        const ellipsisEl = parentEl.querySelector('.dca-more-text-ellipsis')
        const moreTextEl = parentEl.querySelector('.dca-more-text')

        ellipsisEl?.toggleAttribute('hidden')
        moreTextEl?.toggleAttribute('hidden')

        if (!ellipsisEl?.hasAttribute('hidden')) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }

        if (el.hasAttribute('data-alternate-text')) {
          const alternateText = el.getAttribute('data-alternate-text')
          const currentText = el.innerHTML

          el.setAttribute('data-alternate-text', currentText)
          el.innerHTML = alternateText as string
        }
      }
    })
  })
}

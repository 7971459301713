'use strict'

interface EventRecorded {
  el: Element | HTMLElement | Document | Window
  type: keyof HTMLElementEventMap
  fn: EventListenerOrEventListenerObject
}

interface EventsRecorded {
  [key: string]: EventRecorded[]
}

const events: EventsRecorded = {}

export default {
  addEvent(
    id: string,
    el: Element | HTMLElement | Document | Window,
    type: keyof HTMLElementEventMap,
    fn: EventListenerOrEventListenerObject
  ): EventRecorded[] | undefined {
    if (!id || !el || !type || !fn) {
      return
    }

    if (!events[id]) {
      events[id] = []
    }

    events[id]?.push({
      el,
      type,
      fn,
    })

    el.addEventListener(type, fn)

    return events[id]
  },

  getEvent(id: string, index: number): EventRecorded | undefined {
    const event = events[id]

    if (!event || !event[index]) {
      return
    }

    return event[index]
  },

  getAllEvents(id: string): EventRecorded[] | undefined {
    if (!events[id]) {
      return
    }

    return events[id]
  },

  removeEvent(id: string, index: number): true | undefined {
    if (!events[id]) {
      return
    }

    if (Array.isArray(events[id]) && Object.prototype.hasOwnProperty.call(events[id], index)) {
      // @ts-ignore
      const event = events[id][index]

      event?.el.removeEventListener(event.type, event.fn)

      events[id]?.splice(index, 1)

      return true
    }
  },

  removeAllEvents(id: string) {
    if (!events[id]) {
      return
    }

    events[id]?.forEach((event) => {
      event.el.removeEventListener(event.type, event.fn)
    })

    delete events[id]

    return true
  },
}

export default (): void => {
  const elements = document.querySelectorAll<HTMLElement>('.dca-sticky:not([data-sticky-watched])')

  elements.forEach((el) => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (!e || !e.target) {
          return
        }

        const target = e.target as HTMLElement

        if (e.intersectionRatio === 1) {
          target.removeAttribute('data-sticky-pinned')
        } else if (e.intersectionRatio < 1 && el.getBoundingClientRect().left === 0) {
          target.setAttribute('data-sticky-pinned', 'true')
        }
      },
      { threshold: [1] }
    )

    el.setAttribute('data-sticky-watched', 'true')
    observer.observe(el)
  })
}

'use strict'

import ExpandableMenu from 'assets/core/js/module/expandableMenu'
import favoriteMenu from 'assets/core/js/module/favorite/menu'

export default {
  initMenus: function () {
    const expandableMenus = []

    document.querySelectorAll('.header .dca-expandable__menu, .header__nav .dca-expandable__menu').forEach(function (el) {
      expandableMenus.push(new ExpandableMenu(el))
    })

    if (document.getElementById('header-nav')) {
      document.querySelectorAll('.header__nav-toggle').forEach(function (el) {
        el.addEventListener('click', function () {
          document.body.dispatchEvent(
            new CustomEvent(`headerNav.modal.${document.body.classList.contains('header-nav--opened') ? 'closed' : 'opened'}`)
          )
          document.getElementById('header-nav')?.classList.remove('nav--hidden')
          document.body.classList.toggle('header-nav--opened')
          document.body.classList.add('no-scroll')
          document.documentElement.classList.add('no-scroll')
        })
      })

      document.querySelector('.app-content')?.addEventListener('transitionend', (e) => {
        if (!document.body.classList.contains('header-nav--opened') && (e as TransitionEvent).propertyName === 'transform') {
          document.getElementById('header-nav')?.classList.add('nav--hidden')
          document.body.classList.remove('no-scroll')
          document.documentElement.classList.remove('no-scroll')
        }
      })
    }

    if (document.getElementById('header-nav-fade')) {
      document.getElementById('header-nav-fade')?.addEventListener('click', function () {
        if (document.body.classList.contains('header-nav--opened')) {
          document.getElementById('header-nav-toggle')?.click()
        }
      })
    }

    favoriteMenu()
  },
}

'use strict'

import FavoriteRepository, { Repository } from 'assets/core/js/module/favorite/repository'

class Menu {
  element: HTMLElement
  repository: Repository

  constructor(element: HTMLElement, repository: Repository) {
    this.element = element
    this.repository = repository
  }

  initialize(): void {
    this.repository.listen(() => {
      this.update()
    })
    this.update()
  }

  private update(): void {
    const favorites = this.repository.get().length
    if (favorites > 0) {
      this.element.setAttribute('data-favorite-indicator', '')
    } else {
      this.element.removeAttribute('data-favorite-indicator')
    }
  }
}

export default (): void => {
  const element = document.getElementById('favorite_menu')
  if (element === null || !element.hasAttribute('data-favorite-namespace')) {
    return
  }

  const namespace = element.getAttribute('data-favorite-namespace') as string
  const repository = FavoriteRepository.getRepository(namespace)

  const menu = new Menu(element, repository)
  menu.initialize()
}

import { isMobile, isTablet } from 'assets/core/js/common'
import mobileBottomPanel from 'assets/core/js/module/mobileBottomPanel'

export default (): void => {
  mobileBottomPanel('.dca-mobile-bottompanel__toggle[data-panel-target="header-bs-mobile-bottompanel"]')

  document.querySelectorAll<HTMLElement>('.header-menu-bs__section').forEach((el) => {
    const titleEl = el.querySelector('.header-menu-bs__section-title')
    const contentEl = el.querySelector('.header-menu-bs__section-content')
    const buttonEl = el.querySelector('button')

    if (!contentEl || !titleEl || !buttonEl) {
      return
    }

    const toggleFn = (e: Event): void => {
      if (isMobile() || isTablet()) {
        e.preventDefault()
      }

      if (contentEl.hasAttribute('data-visible')) {
        contentEl.removeAttribute('data-visible')
        return
      }

      contentEl.setAttribute('data-visible', 'true')
    }

    titleEl.addEventListener('click', toggleFn)
    buttonEl.addEventListener('click', toggleFn)
  })
}

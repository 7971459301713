'use strict'

import HelpTooltipManager from 'assets/core/js/module/helpTooltipManager'

export default function (): void {
  document.querySelectorAll<HTMLElement>('[data-help-message]').forEach(function (el) {
    const id = el.getAttribute('data-help-id') as string
    const helpTooltip = HelpTooltipManager.initHelpTooltip(id, {
      root: el.parentNode as HTMLElement,
      id,
      alignment: el.getAttribute('data-help-align') as string,
      message: el.getAttribute('data-help-message') as string,
    })

    if (helpTooltip) {
      helpTooltip.open()
    }
  })
}

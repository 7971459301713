'use strict'

export default {
  init: function () {
    this.initList()
    this.initFormFields()
  },

  initList: function () {
    document.querySelectorAll('.dca-list.dca-list--checkboxes .dca-list__item').forEach(function (el) {
      el.addEventListener('click', function (this: HTMLElement, e) {
        const target = e.target as HTMLElement
        if (target.nodeName === 'INPUT') {
          this.classList.toggle('dca-list__item--selected')
        }
      })
    })
  },

  initFormFields: function () {
    const focusBlockFn = function (this: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
      this.closest('.dca-form__field-block')?.classList.add('dca-form__field--focused')
    }

    const filledBlockFn = function (this: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): void {
      this.closest('.dca-form__field-block')?.classList.remove('dca-form__field--focused')

      setTimeout(() => {
        if (this.value === '') {
          this.closest('.dca-form__field-block')?.classList.remove('dca-form__field--filled')
        } else {
          this.closest('.dca-form__field-block')?.classList.add('dca-form__field--filled')
        }
      }, 150)
    }

    document.querySelectorAll('.dca-form__field:not(.dca-form__field--switch)').forEach(function (el) {
      el.addEventListener('click', focusBlockFn)
      el.addEventListener('focus', focusBlockFn)

      el.addEventListener('blur', filledBlockFn)
      el.addEventListener('change', filledBlockFn)
    })

    const syncChecked = (field: HTMLInputElement): void =>
      field.closest('.dca-form__field-block')?.classList[field.checked ? 'add' : 'remove']('dca-form__field--checked')

    document.querySelectorAll<HTMLInputElement>('.dca-form__field--switch').forEach((element) => {
      syncChecked(element)
      element.addEventListener('click', (e) => {
        const target = e.target

        if (!target) {
          return
        }

        syncChecked(target as HTMLInputElement)
      })
    })
  },
}

'use strict'

export default function (): void {
  const languageSelectors = [...document.querySelectorAll<HTMLDivElement>('.dca-language-selector')]

  document.body.addEventListener('click', (e) => {
    const target = e.target as HTMLElement

    if (!languageSelectors.some((el) => el.contains(target))) {
      languageSelectors.forEach((el) => el.removeAttribute('open'))
    } else {
      languageSelectors.forEach((el) => (!el.contains(target) ? el.removeAttribute('open') : ''))
    }
  })
}

'use strict'

import HelpTooltip, { HelpTooltipConfig } from 'assets/core/js/module/helpTooltip'

interface HelpTooltips {
  [key: string]: HelpTooltip
}

const helpTooltips: HelpTooltips = {}

export default {
  initHelpTooltip(id: string, config: HelpTooltipConfig) {
    if (!id) {
      return
    }

    if (helpTooltips[id]) {
      return
    }

    helpTooltips[id] = new HelpTooltip(config)

    return helpTooltips[id]
  },

  getHelpTooltip(id: string): HelpTooltip | undefined {
    if (!helpTooltips[id]) {
      return
    }

    return helpTooltips[id]
  },
}

'use strict'

import * as coreCommon from 'assets/core/js/common'
import BrowserStorage from 'assets/core/js/module/browserStorage'
import EventManager from './eventManager'

export interface HelpTooltipConfig {
  alignment: string
  id: string
  root: Element
  message: string
  onInit?: () => void
  onOpen?: () => void
  onClose?: () => void
}

/* istanbul ignore next */
const defaultConfig = {
  alignment: 'right',
  onInit: function () {
    /* nothing */
  },
  onOpen: function () {
    /* nothing */
  },
  onClose: function () {
    /* nothing */
  },
}

export default class HelpTooltip {
  config!: HelpTooltipConfig
  isOpened!: boolean
  element!: Element
  closeEl!: Element
  id!: string

  constructor(config: HelpTooltipConfig) {
    this.config = Object.assign({}, defaultConfig, config)

    if (!this.config.id || !this.config.root || !this.config.message) {
      return
    }

    let alignmentClass = 'dca-help__tooltip--right'

    if (config.alignment && config.alignment === 'left') {
      alignmentClass = 'dca-help__tooltip--left'
    }

    const id = `${this.config.id}-help-tooltip`
    const template = `<div class="dca-help__tooltip ${alignmentClass}" id="${id}"><button type="button"></button><p>${this.config.message}</p></div>`

    const templateEl = coreCommon.createNodeFromHTML(template)

    this.config.root.appendChild(templateEl)

    this.config.onInit = this.config.onInit && this.config.onInit.bind(this)
    this.config.onOpen = this.config.onOpen && this.config.onOpen.bind(this)
    this.config.onClose = this.config.onClose && this.config.onClose.bind(this)

    this.isOpened = false
    this.id = id
    this.element = document.getElementById(id) as Element
    this.closeEl = this.element.querySelector('button') as Element

    if (this.config.onInit) {
      this.config.onInit()
    }

    this.initEvents()
  }

  initEvents(): void {
    const closeClick = (): void => {
      this.close()
      BrowserStorage.setItem('session', `${this.config.id}-help-hide`, '1')
    }

    EventManager.addEvent(this.id, this.element, 'click', closeClick)

    this.closeEl.addEventListener('click', closeClick)
  }

  getElement(): Element {
    return this.element
  }

  open(): void {
    if (BrowserStorage.getItem('session', `${this.config.id}-help-hide`) !== '1') {
      this.element.classList.add('dca-help__tooltip--visible')
      this.isOpened = true

      if (this.config.onOpen) {
        this.config.onOpen()
      }
    }
  }

  close(): void {
    if (!this.isOpened) {
      return
    }

    this.element.classList.remove('dca-help__tooltip--visible')
    this.isOpened = false

    if (this.config.onClose) {
      this.config.onClose()
    }
  }
}

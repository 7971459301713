type Data = Record<string, object | unknown[] | number | string>

export interface PageData {
  getDataForTag(gtmId: string): Data
  addData(tagId: string, pageData: Data): void
}

export type DataLayer = Record<string | number, unknown> | IArguments | false
type TransformElement = (data: Data, el: Element, domEvent: Event | null) => DataLayer
type TransformElements = (data: Data, el: Element[], domEvent: Event | null) => DataLayer
type Elements = NodeListOf<Element> | Element[] | Element

declare global {
  interface Window {
    pageDataLayer: Record<string, Array<DataLayer>>
    pageDataLayerCache: Record<string, Array<DataLayer>>
    pageData: PageData
    hasConsentMode: boolean
  }
}

interface TagData {
  [key: string]: {
    initialState: [{ data: unknown }]
    pageData: Record<string, unknown>
  }
}

interface TagDatas {
  gtm: TagData
}

const dataLayer = {
  hasResponseToConsent: false,

  pushInLayer(gtmId: string, data: DataLayer, byPassResponseToConsent = false) {
    if (window.pageDataLayer[gtmId] && data) {
      for (const key in data) {
        if (data[key] === undefined) {
          delete data[key]
        }
      }
      if (byPassResponseToConsent || this.hasResponseToConsent) {
        window.pageDataLayer[gtmId]?.push(data)
      } else {
        window.pageDataLayerCache[gtmId]?.push(data)
      }
    }
  },

  pushOnElementClick(gtmId: string, element: Elements, transform: TransformElement) {
    if (!transform) {
      return
    }
    const elements = this.normalizeElements(element)
    elements.forEach((el) => {
      el.addEventListener('click', (domEvent) => {
        this.pushInLayer(gtmId, transform(this.getData(gtmId), el, domEvent))
      })
    })
  },

  pushOnElementSubmit(gtmId: string, element: Elements, transform: TransformElement) {
    if (!transform) {
      return
    }
    const elements = this.normalizeElements(element)
    elements.forEach((element) => {
      const el = element.querySelector('[type="submit"]')

      if (el) {
        el.addEventListener('click', (domEvent) => {
          this.pushInLayer(gtmId, transform(this.getData(gtmId), el, domEvent))
        })
      }
    })
  },

  pushOnCustomEvent(gtmId: string, element: EventTarget, customEvent: string, transform: TransformElement) {
    if (!element || !customEvent || !transform) {
      return
    }

    // @ts-ignore
    element.addEventListener(customEvent, (domEvent) => {
      this.pushInLayer(gtmId, transform(this.getData(gtmId), element as Element, domEvent))
    })
  },

  pushOnElementVisible(gtmId: string, element: Elements, transform: TransformElements, threshold = 0.5) {
    const elements = this.normalizeElements(element)
    if (elements.length === 0 || !transform) {
      return
    }

    const callback: IntersectionObserverCallback = (entries, observer) => {
      const els = entries.filter((entry) => entry.isIntersecting).map((entry) => entry.target)
      if (!els.length) {
        return
      }
      this.pushInLayer(gtmId, transform(this.getData(gtmId), els, null))
      els.forEach((elt) => observer.unobserve(elt))
    }

    const observer = new IntersectionObserver(callback, { threshold })
    elements.forEach((el) => observer.observe(el))
  },

  pushForJsonResponse(tagDatas: TagDatas) {
    if (!tagDatas || !tagDatas.gtm) {
      return
    }

    const tagDatasGtm: TagData = tagDatas.gtm
    for (const tagId in tagDatasGtm) {
      tagDatasGtm[tagId]?.initialState.forEach((data: Record<string, unknown>) => {
        this.pushInLayer(tagId, data)
      })
      // @ts-ignore
      if (tagDatasGtm[tagId].pageData) {
        // @ts-ignore
        window.pageData.addData(tagId, tagDatasGtm[tagId].pageData)
      }
    }
  },

  onResponseToConsent() {
    this.hasResponseToConsent = true
    // We have a response to consent, we push data from cache to layer
    for (const gtmId in window.pageDataLayerCache) {
      const data = window.pageDataLayerCache[gtmId]
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.pushInLayer(gtmId, data[i] || false)
        }
        window.pageDataLayerCache[gtmId] = []
      }
    }
  },

  normalizeElements(element: NodeListOf<Element> | Element[] | Element): Element[] {
    if (!element) {
      return []
    }

    // @ts-ignore
    if (typeof element.forEach === 'function') {
      return element as Element[]
    }

    // @ts-ignore
    if (typeof element[Symbol.iterator] === 'function') {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return [...element]
    }

    return element ? [element as Element] : []
  },

  getData(gtmId: string): Data {
    return window.pageData.getDataForTag(gtmId)
  },

  events: {
    SEARCH_VIEW_PRODUCTS: 'view_item_list',
    SEARCH_SELECT_PRODUCT: 'select_item',
    PRODUCT_VIEW_ACCOMMODATIONS: 'view_item_list',
    PRODUCT_SELECT_ACCOMMODATION: 'select_item',
    PRODUCT_BEGIN_CHECKOUT: 'begin_booking',
    CHECKOUT_SELECT_PAYMENT_METHOD: 'begin_payment',
    CHECKOUT_SECTION_TRAVELLERS: 'view_guests_details',
    CHECKOUT_SECTION_OPTIONS: 'view_stay_options',
    FAVORITE_ADDED: 'add_to_favorite',
    FAVORITE_REMOVED: 'remove_from_favorite',
    CUSTOMER_ZONE_VOUCHER_DOWNLOAD: 'download_voucher',
    // Event names for old tagguage
    OLD_PRODUCT_CHECK_OFFER: 'CheckOffer',
    OLD_SEARCH: 'searchResults',
    OLD_HOMEPAGE_CMS: 'homeClick',
    SEARCH_MAP_OPEN: 'view_map',
    SEARCH_MAP_CLOSE: 'close_map',
    SEARCH_MAP_MOVE: 'move_map',
    SEARCH_MAP_SELECT_PRODUCT: 'pin_map',
    ACCOMMODATION_PLAN_VIEW: 'view_accommodation_plan',
  },

  // Keys to get choice for consent mode
  consentMode: {
    googleAnalytics: 'google_analytics',
  },
}

export default dataLayer

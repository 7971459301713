'use strict'

import browserStorage from 'assets/core/js/module/browserStorage'

export class Favorite {
  private identifier: string

  constructor(identifier: string) {
    this.identifier = identifier
  }

  /**
   * Get the identifier of this favorite.
   */
  getIdentifier(): string {
    return this.identifier
  }

  /**
   * Check if this favorite is the same as the given favorite.
   */
  sameAs(favorite: Favorite): boolean {
    return this.identifier === favorite.identifier
  }
}

/**
 * A differential between two favorite list.
 */
export class Differential {
  added: Favorite[]
  removed: Favorite[]

  constructor(added: Favorite[], removed: Favorite[]) {
    this.added = added
    this.removed = removed
  }

  getAdded(): Favorite[] {
    return this.added
  }

  getRemoved(): Favorite[] {
    return this.removed
  }
}

interface JsonedFavorite {
  identifier: string
}

function validate(item: unknown): item is JsonedFavorite {
  if (item === null || typeof item !== 'object' || !('identifier' in item) || typeof item.identifier !== 'string') {
    return false
  }

  return true
}

function calculateDifferential(oldFavorites: Favorite[], newFavorites: Favorite[]): Differential {
  const added = newFavorites.filter((newFavorite: Favorite) => {
    const index = oldFavorites.findIndex((oldFavorite: Favorite) => {
      return newFavorite.sameAs(oldFavorite)
    })

    return index === -1
  })
  const removed = oldFavorites.filter((oldFavorite: Favorite) => {
    const index = newFavorites.findIndex((newFavorite: Favorite) => {
      return newFavorite.sameAs(oldFavorite)
    })

    return index === -1
  })

  return new Differential(added, removed)
}

type Listener = (differential: Differential) => void

/**
 * Favorite repositories, instanciated for a given namespace. It allows to add,
 * remove and retrieve favorites through different useful methods, and to be
 * notified from changes in the favorite list by adding listeners.
 */
export class Repository {
  private storageKey: string
  private listeners: Listener[]
  /**
   * A consolidation of favorites of this repository, used to calculate the
   * differential between a previous and current favorite list. It is used only
   * when at least one listener is registered, and is `null` otherwise. It
   * stores the last known favorite list. It's updated every time the list
   * changes.
   */
  private favorites: Favorite[] | null

  constructor(namespace: string) {
    this.storageKey = `favorite.repository.${namespace}`
    this.listeners = []
    this.favorites = null
  }

  /**
   * Find all favorite items in the current local storage, in the reverse
   * insertion order (newer favorites first).
   */
  get(): Favorite[] {
    return this.read()
  }

  /**
   * Check if the given favorite is in the current local storage.
   */
  has(favorite: Favorite): boolean {
    const currentFavorites = this.read()

    const index = currentFavorites.findIndex((currentFavorite) => {
      return currentFavorite.sameAs(favorite)
    })

    return index !== -1
  }

  /**
   * Add the given favorite items to the current favorite list. There is a hard
   * limit set to 100 on the maximum number of items in the favorite list. When
   * trying to add favorites above this limit, it returns false, without adding
   * any of the given favorites.
   */
  add(favorites: Favorite[]): boolean {
    const currentFavorites = this.read()
    let changes = false

    for (const favorite of favorites) {
      const index = currentFavorites.findIndex((currentFavorite) => {
        return currentFavorite.sameAs(favorite)
      })

      if (index === -1) {
        currentFavorites.unshift(favorite)
        changes = true

        if (currentFavorites.length > 100) {
          return false
        }
      }
    }

    if (changes) {
      this.write(currentFavorites)
    }

    return true
  }

  /**
   * Remove the given favorite items from the current favorite list.
   */
  remove(favorites: Favorite[]): void {
    const currentFavorites = this.read()
    let changes = false

    const newFavorites = []
    for (const favorite of currentFavorites) {
      const index = favorites.findIndex((currentFavorite) => {
        return currentFavorite.sameAs(favorite)
      })

      if (index === -1) {
        newFavorites.push(favorite)
      } else {
        changes = true
      }
    }

    if (changes) {
      this.write(newFavorites)
    }
  }

  /**
   * Register the given listener to be called on favorites changes.
   */
  listen(listener: Listener): void {
    // Listen to the browserStorage for the first listener only.
    if (this.listeners.length === 0) {
      // Initialize consolidated favorites.
      this.favorites = this.read()

      browserStorage.listen('local', this.storageKey, () => {
        // Calculate a differential.
        const oldFavorites = this.favorites as Favorite[]
        const newFavorites = this.read()
        const differential = calculateDifferential(oldFavorites, newFavorites)

        // Trigger all listeners.
        for (const listener of this.listeners) {
          listener(differential)
        }

        this.favorites = newFavorites
      })
    }

    this.listeners.push(listener)
  }

  private read(): Favorite[] {
    const data = browserStorage.getItem('local', this.storageKey)
    if (data === false) {
      return []
    }

    if (!Array.isArray(data)) {
      browserStorage.removeItem('local', this.storageKey)

      return []
    }

    const favorites = []
    for (const item of data) {
      if (validate(item)) {
        favorites.push(new Favorite(item.identifier))
      }
    }

    return favorites
  }

  private write(favorites: Favorite[]): void {
    browserStorage.setItem('local', this.storageKey, favorites)
  }
}

const repositories: Map<string, Repository> = new Map()

export default {
  /**
   * Get the repository for the given namespace. A repository is instanciated a
   * single time for each namespace (thus allowing listeners to work properly
   * accross all scripts on a single page).
   */
  getRepository: (namespace: string): Repository => {
    if (repositories.has(namespace)) {
      return repositories.get(namespace) as Repository
    }

    const repository = new Repository(namespace)
    repositories.set(namespace, repository)

    return repository
  },
}
